import { graphql, useStaticQuery } from 'gatsby';

export interface SanitySocialMedia {
  facebookUsername: string | null;
  instagramUsername: string | null;
  spotifyId: string | null;
  youtubeChannel: string | null;
}

export function useSanitySocialMedia() {
  const { sanitySocialMedia } = useStaticQuery<Queries.SanitySocialMediaQuery>(graphql`
    query SanitySocialMedia {
      sanitySocialMedia {
        facebookUsername
        instagramUsername
        spotifyId
        youtubeChannel
      }
    }
  `);

  return sanitySocialMedia as SanitySocialMedia;
}
