import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

export const SpotifyIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 38.6 38.6">
    <path
      d="M19.3,8A19.3,19.3,0,1,0,38.6,27.3,19.307,19.307,0,0,0,19.3,8Zm7.837,28.4a1.485,1.485,0,0,1-.833-.28c-4.856-2.926-10.506-3.051-16.086-1.907a5.025,5.025,0,0,1-.926.2,1.212,1.212,0,0,1-1.23-1.23,1.233,1.233,0,0,1,1.058-1.307c6.374-1.409,12.887-1.284,18.444,2.039A1.316,1.316,0,0,1,28.32,35.2,1.176,1.176,0,0,1,27.137,36.4Zm2.093-5.105a1.922,1.922,0,0,1-.957-.327A25.22,25.22,0,0,0,9.7,28.677a3.205,3.205,0,0,1-.926.2,1.511,1.511,0,0,1-1.51-1.51,1.531,1.531,0,0,1,1.206-1.611A26.176,26.176,0,0,1,16.086,24.7a27.242,27.242,0,0,1,13.775,3.541,1.622,1.622,0,0,1,.879,1.533A1.508,1.508,0,0,1,29.23,31.292Zm2.413-5.93a1.79,1.79,0,0,1-1-.3c-5.541-3.307-15.448-4.1-21.86-2.311a3.7,3.7,0,0,1-1,.2,1.8,1.8,0,0,1-1.813-1.837,1.839,1.839,0,0,1,1.354-1.86,32.616,32.616,0,0,1,9.144-1.183c5.681,0,11.634,1.183,15.985,3.72a1.827,1.827,0,0,1,1,1.759,1.8,1.8,0,0,1-1.805,1.813Z"
      transform="translate(0 -8)"
    />
  </SvgIcon>
);
