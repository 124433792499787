module.exports = [{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"render-blocking","preconnect":["https://fonts.gstatic.com","https://fonts.googleapis.com","https://use.typekit.net"],"web":[{"name":["Staatliches","Krub"],"file":"https://fonts.googleapis.com/css2?family=Staatliches&family=Krub:wght@400;600"},{"name":["Bourbon"],"file":"https://use.typekit.net/xmc8exu.css"}]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
