import React from 'react';

import { Stack } from '@mui/material';

import { useSanitySocialMedia } from '../hooks/useSanitySocialMedia';

import { FacebookIcon } from './FacebookIcon';
import { InstagramIcon } from './InstagramIcon';
import { Link } from './Link';
import { SpotifyIcon } from './SpotifyIcon';
import { YoutubeIcon } from './YoutubeIcon';

export function SocialMediaButtons() {
  const some = useSanitySocialMedia();

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      {some.spotifyId && (
        <Link
          aria-label="Spotify"
          color="secondary.main"
          sx={{ '&:hover': { color: 'primary.main' } }}
          to={`https://open.spotify.com/artist/${some.spotifyId}`}
        >
          <SpotifyIcon sx={{ fontSize: 36 }} />
        </Link>
      )}

      {some.facebookUsername && (
        <Link
          aria-label="Facebook"
          color="secondary.main"
          sx={{ '&:hover': { color: 'primary.main' } }}
          to={`https://www.facebook.com/${some.facebookUsername}`}
        >
          <FacebookIcon sx={{ fontSize: 36 }} />
        </Link>
      )}

      {some.instagramUsername && (
        <Link
          aria-label="Instagram"
          color="secondary.main"
          sx={{ '&:hover': { color: 'primary.main' } }}
          to={`https://www.instagram.com/${some.instagramUsername}`}
        >
          <InstagramIcon sx={{ fontSize: 36 }} />
        </Link>
      )}

      {some.youtubeChannel && (
        <Link
          aria-label="YouTube"
          color="secondary.main"
          sx={{ '&:hover': { color: 'primary.main' } }}
          to={`https://www.youtube.com/${some.youtubeChannel}`}
        >
          <YoutubeIcon sx={{ fontSize: 48 }} />
        </Link>
      )}
    </Stack>
  );
}
