import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

export const InstagramIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 36.376 36.368">
    <path
      d="M18.117,40.685a9.324,9.324,0,1,0,9.324,9.324A9.31,9.31,0,0,0,18.117,40.685Zm0,15.386a6.062,6.062,0,1,1,6.062-6.062,6.073,6.073,0,0,1-6.062,6.062ZM30,40.3a2.175,2.175,0,1,1-2.175-2.175A2.17,2.17,0,0,1,30,40.3Zm6.176,2.207c-.138-2.913-.8-5.494-2.938-7.62s-4.707-2.792-7.62-2.938c-3-.17-12-.17-15.005,0-2.905.138-5.486.8-7.62,2.93S.2,39.589.053,42.5c-.17,3-.17,12,0,15.005.138,2.913.8,5.494,2.938,7.62s4.707,2.792,7.62,2.938c3,.17,12,.17,15.005,0,2.913-.138,5.494-.8,7.62-2.938s2.792-4.707,2.938-7.62c.17-3,.17-11.994,0-15ZM32.295,60.729a6.137,6.137,0,0,1-3.457,3.457c-2.394.949-8.075.73-10.72.73s-8.334.211-10.72-.73A6.137,6.137,0,0,1,3.94,60.729c-.949-2.394-.73-8.075-.73-10.72S3,41.675,3.94,39.289A6.137,6.137,0,0,1,7.4,35.832c2.394-.949,8.075-.73,10.72-.73s8.334-.211,10.72.73a6.137,6.137,0,0,1,3.457,3.457c.949,2.394.73,8.075.73,10.72S33.244,58.344,32.295,60.729Z"
      transform="translate(0.075 -31.825)"
    />
  </SvgIcon>
);
