import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

export const FacebookIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 39.237 39">
    <path
      d="M47.237,27.619A19.619,19.619,0,1,0,24.553,47V33.29H19.569V27.619h4.984V23.3c0-4.917,2.927-7.632,7.41-7.632a30.193,30.193,0,0,1,4.392.383v4.826H33.881c-2.437,0-3.2,1.513-3.2,3.064v3.682h5.441l-.87,5.671H30.684V47A19.626,19.626,0,0,0,47.237,27.619Z"
      transform="translate(-8 -8)"
    />
  </SvgIcon>
);
