import { graphql, useStaticQuery } from 'gatsby';

export const useSiteMetadata = () => {
  const { site } = useStaticQuery<Queries.SiteMetadataQuery>(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          siteUrl
          title
          description
        }
      }
    }
  `);

  if (!site?.siteMetadata) {
    throw new Error('Failed to query site metadata');
  }

  return site.siteMetadata;
};
