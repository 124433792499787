exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-diskografia-tsx": () => import("./../../../src/pages/diskografia.tsx" /* webpackChunkName: "component---src-pages-diskografia-tsx" */),
  "component---src-pages-media-tsx": () => import("./../../../src/pages/media.tsx" /* webpackChunkName: "component---src-pages-media-tsx" */),
  "component---src-pages-yhteystiedot-tsx": () => import("./../../../src/pages/yhteystiedot.tsx" /* webpackChunkName: "component---src-pages-yhteystiedot-tsx" */),
  "component---src-pages-yhtye-historia-tsx": () => import("./../../../src/pages/yhtye/historia.tsx" /* webpackChunkName: "component---src-pages-yhtye-historia-tsx" */),
  "component---src-pages-yhtye-index-tsx": () => import("./../../../src/pages/yhtye/index.tsx" /* webpackChunkName: "component---src-pages-yhtye-index-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-keikat-tsx": () => import("./../../../src/templates/keikat.tsx" /* webpackChunkName: "component---src-templates-keikat-tsx" */)
}

