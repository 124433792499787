import React, { PropsWithoutRef } from 'react';
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';

import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';

const isExternalLink = (path: string) => {
  return (
    path.startsWith('//') ||
    path.startsWith('http://') ||
    path.startsWith('https://') ||
    path.startsWith('callto:') ||
    path.startsWith('tel:') ||
    path.startsWith('mailto:')
  );
};

const isCallableLink = (path: string) => {
  return path.startsWith('mailto://') || path.startsWith('tel://');
};

export type LinkProps = MuiLinkProps & GatsbyLinkProps<{}>;

export const Link = ({
  children,
  target,
  to,
  state,
  activeClassName,
  ...rest
}: PropsWithoutRef<LinkProps>) => {
  if (!to) {
    return <>{children}</>;
  }

  if (isExternalLink(to)) {
    return (
      <MuiLink {...rest} href={to} target="_blank">
        {children}
      </MuiLink>
    );
  }

  if (isCallableLink(to)) {
    return (
      <MuiLink {...rest} href={to}>
        {children}
      </MuiLink>
    );
  }

  return (
    <MuiLink
      component={GatsbyLink}
      activeClassName={activeClassName}
      to={to}
      state={state}
      {...rest}
    >
      {children}
    </MuiLink>
  );
};
