import React from 'react';
import { GatsbyBrowser } from 'gatsby';
import { CssBaseline, ThemeProvider } from '@mui/material';

import { Layout } from './src/components/Layout';
import './src/styles/global.css';
import { theme } from './src/styles/theme';

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({ routerProps }) => {
  if (routerProps.location.state?.from === 'tab') {
    return false;
  }
  return true;
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  return <Layout>{element}</Layout>;
};

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {element}
    </ThemeProvider>
  );
};
