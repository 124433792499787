import {} from '@mui/lab/themeAugmentation';
import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      // main: '#92D1C3',
      main: '#F9B433',
    },
    secondary: {
      main: '#FFFFFF',
    },
    background: {
      default: '#000000',
    },
    text: {
      primary: '#FFFFFF',
    },
  },
  shape: {
    borderRadius: 0,
  },
});

theme.typography.fontFamily = 'Krub, Arial, sans-serif';

theme.typography.h1 = {
  fontFamily: 'Bourbon, Arial, sans-serif',
  fontSize: 75,
  fontWeight: 400,
  textTransform: 'uppercase',
  letterSpacing: 1,
  [theme.breakpoints.down('md')]: {
    fontSize: 52,
  },
};

theme.typography.h2 = {
  fontFamily: 'Staatliches, Arial, sans-serif',
  fontSize: 60,
  fontWeight: 400,
  letterSpacing: 1,
  textTransform: 'uppercase',
  [theme.breakpoints.down('md')]: {
    fontSize: 44,
  },
};

theme.typography.h3 = {
  fontFamily: 'Staatliches, Arial, sans-serif',
  fontWeight: 400,
  fontSize: 45,
  lineHeight: 1,
  letterSpacing: 1,
  textTransform: 'uppercase',
  [theme.breakpoints.down('md')]: {
    fontSize: 34,
  },
};

theme.typography.h4 = {
  fontFamily: 'Staatliches, Arial, sans-serif',
  fontWeight: 400,
  fontSize: 34,
  letterSpacing: 1,
  textTransform: 'uppercase',
};

theme.typography.h5 = {
  fontFamily: 'Staatliches, Arial, sans-serif',
  fontWeight: 400,
  fontSize: 28,
  textTransform: 'uppercase',
  letterSpacing: 1,
};

theme.typography.subtitle1 = {
  fontFamily: 'Krub, Arial, sans-serif',
  fontWeight: 600,
  fontSize: 25,
  [theme.breakpoints.down('md')]: {
    fontSize: 22,
  },
};

theme.typography.body1 = {
  fontFamily: 'Krub, Arial, sans-serif',
  fontSize: 25,
  fontWeight: 400,
};

theme.typography.body2 = {
  fontFamily: 'Krub, Arial, sans-serif',
  fontSize: 20,
  fontWeight: 400,
};

theme.typography.caption = {
  fontFamily: 'Krub, Arial, sans-serif',
  fontSize: 16,
  fontWeight: 400,
};

theme.components = {
  MuiButton: {
    defaultProps: {
      variant: 'outlined',
      sx: {
        '&.Mui-disabled': {
          bgcolor: `${theme.palette.primary.main}8B`,
        },
      },
    },
    styleOverrides: {
      root: {
        fontFamily: 'Staatliches, Arial, sans-serif',
        fontSize: 26,
        borderRadius: 64,
        padding: `${theme.spacing(0)} ${theme.spacing(7)}`,
        whiteSpace: 'nowrap',
        boxShadow: 'none !important',
        borderWidth: '3px !important',
      },
      contained: {
        border: `3px solid transparent`,
      },
      outlinedPrimary: {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        '&:hover': {
          color: theme.palette.primary.dark,
          borderColor: theme.palette.primary.dark,
          backgroundColor: 'transparent',
        },
      },
      text: {
        position: 'relative',
        color: theme.palette.common.white,
        transition: theme.transitions.create('color'),
        textDecoration: 'none',
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        background: theme.palette.common.black,
        height: '100%',
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        position: 'relative',
        transition: theme.transitions.create('color'),
        textDecoration: 'none',
        '&:hover': {
          color: `${theme.palette.primary.dark}`,
        },
      },
      underlineHover: {
        '&:after': {
          position: 'absolute',
          bottom: 0,
          left: 0,
          content: '""',
          width: '100%',
          height: '3px',
          backgroundColor: theme.palette.primary.main,
          opacity: 0,
          transition: theme.transitions.create(['opacity', 'transform']),
          transform: 'scaleX(0)',
          transformOrigin: 'center',
        },
        '&:hover:after': {
          backgroundColor: `${theme.palette.primary.main} !important`,
          opacity: 1,
          transform: 'scaleX(1)',
        },
        '&:hover': {
          color: theme.palette.primary.main,
          textDecoration: 'none',
        },
        '&.active:after': {
          backgroundColor: theme.palette.common.white,
          opacity: 1,
          transform: 'scaleX(1)',
        },
      },
    },
  },
  MuiModal: {
    styleOverrides: {
      root: {
        zIndex: 6000,
      },
    },
  },
};
