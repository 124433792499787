import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';

import prerLogo from '../assets/prer-logo.svg';
import prerTeksti from '../assets/prer-teksti.svg';
import { useSiteMetadata } from '../hooks/useSiteMetadata';

import { SocialMediaButtons } from './SocialMediaButtons';

export const Footer = () => {
  const { description } = useSiteMetadata();

  return (
    <Box component="footer" py={[6, 6, 6]}>
      <Container maxWidth="lg">
        <Grid container alignItems="flex-start" spacing={[4, 4, 1]} justifyContent="center">
          <Grid item xs={12} md={8}>
            <Stack spacing={4}>
              <Stack direction="row" gap={3}>
                <Box component="img" src={prerLogo} width={96} alt="PRER" />
                <Box
                  component="img"
                  src={prerTeksti}
                  width={356}
                  display={['none', 'block']}
                  alt="Prinssi Rohkea ja Erämaan Rotat"
                />
              </Stack>

              <Stack>
                <Typography variant="body2">{description}</Typography>
              </Stack>

              <Stack gap={1}>
                <Typography component="div" variant="subtitle1">
                  Seuraa meitä
                </Typography>
                <SocialMediaButtons />
              </Stack>

              <Stack>
                <Typography variant="caption">
                  Copyright &copy; 2023 Prinssi Rohkea ja Erämaan Rotat
                </Typography>
              </Stack>
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            textAlign={['center', 'center', 'right']}
            sx={(theme) => ({
              '& img': {
                border: `6px solid ${theme.palette.common.white}`,
                borderRadius: '50%',
                width: [256, 288, '100%'],
              },
            })}
          >
            <StaticImage src="../assets/rotta.png" layout="constrained" alt="Rottatunnus" />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
