import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

export const YoutubeIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 52.334 36.798">
    <path
      d="M66.174,69.758A6.576,6.576,0,0,0,61.547,65.1C57.466,64,41.1,64,41.1,64s-16.365,0-20.447,1.1a6.576,6.576,0,0,0-4.627,4.657c-1.094,4.108-1.094,12.678-1.094,12.678s0,8.571,1.094,12.678A6.478,6.478,0,0,0,20.653,99.7c4.081,1.1,20.447,1.1,20.447,1.1s16.365,0,20.447-1.1a6.478,6.478,0,0,0,4.627-4.583c1.094-4.108,1.094-12.678,1.094-12.678S67.267,73.865,66.174,69.758ZM35.748,90.217V74.655l13.678,7.782Z"
      transform="translate(-14.933 -64)"
    />
  </SvgIcon>
);
