import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

import { Footer } from './Footer';

export type LayoutProps = {
  children: ReactNode;
};

export const Layout = ({ children }: LayoutProps) => (
  <>
    <Box component="main">{children}</Box>
    <Footer />
  </>
);
